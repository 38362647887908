var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","small":"","color":"primary"}},on),[_vm._v(" "+_vm._s(_vm.$t('cv.addNewMasc'))+" ")])]}}]),model:{value:(_vm.editMode),callback:function ($$v) {_vm.editMode=$$v},expression:"editMode"}},[_c('v-card',{attrs:{"rounded":"xl"}},[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t('cv.addNewProject')))])]),_c('v-card-text',[_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{attrs:{"label":_vm.$t('auth.name'),"counter":_vm.nameMaxLength,"rules":_vm.rules.name},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('v-checkbox',{attrs:{"label":_vm.$t('cv.projectAlreadyEnded'),"hide-details":""},model:{value:(_vm.projectAlreadyEnded),callback:function ($$v) {_vm.projectAlreadyEnded=$$v},expression:"projectAlreadyEnded"}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('offers.start'),"rules":_vm.rules.timestamp.start,"prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.textDate.start),callback:function ($$v) {_vm.$set(_vm.textDate, "start", $$v)},expression:"textDate.start"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu.start),callback:function ($$v) {_vm.$set(_vm.menu, "start", $$v)},expression:"menu.start"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"input":function($event){_vm.textDate.start = _vm.timestamp.start
									_vm.menu.start = false}},model:{value:(_vm.timestamp.start),callback:function ($$v) {_vm.$set(_vm.timestamp, "start", $$v)},expression:"timestamp.start"}})],1)],1),(_vm.projectAlreadyEnded)?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
									var on = ref.on;
									var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('cv.end'),"rules":_vm.rules.timestamp.end,"prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.textDate.end),callback:function ($$v) {_vm.$set(_vm.textDate, "end", $$v)},expression:"textDate.end"}},'v-text-field',attrs,false),on))]}}],null,false,2172711352),model:{value:(_vm.menu.end),callback:function ($$v) {_vm.$set(_vm.menu, "end", $$v)},expression:"menu.end"}},[_c('v-date-picker',{attrs:{"scrollable":""},on:{"input":function($event){_vm.textDate.end = _vm.timestamp.end
									_vm.menu.end = false}},model:{value:(_vm.timestamp.end),callback:function ($$v) {_vm.$set(_vm.timestamp, "end", $$v)},expression:"timestamp.end"}})],1)],1):_vm._e()],1),_c('v-text-field',{attrs:{"label":_vm.$t('companies.website'),"counter":_vm.websiteMaxLength,"rules":_vm.rules.website,"prepend-icon":"mdi-earth"},model:{value:(_vm.website),callback:function ($$v) {_vm.website=$$v},expression:"website"}}),_c('v-textarea',{attrs:{"label":_vm.$t('courses.description'),"counter":_vm.descriptionMaxLength,"rules":_vm.rules.description,"outlined":"","auto-grow":""},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary","text":""},on:{"click":function($event){return _vm.switchEditMode()}}},[_vm._v(_vm._s(_vm.$t('search.close')))]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.callAddProjectNew()}}},[_vm._v(_vm._s(_vm.$t('settings.add')))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }