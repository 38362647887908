<template>
	<v-dialog v-model="editMode" persistent max-width="600px">
		<template #activator="{ on }">
			<v-btn text small color="primary" v-on="on">
				{{ $t('cv.addNewMasc') }}
			</v-btn>
		</template>
		<v-card rounded="xl">
			<v-card-title>
				<span class="headline">{{ $t('cv.addNewProject') }}</span>
			</v-card-title>
			<v-card-text>
				<v-form ref="form" v-model="valid">
					<v-text-field v-model="name" :label="$t('auth.name')" :counter="nameMaxLength" :rules="rules.name" />
					<v-checkbox v-model="projectAlreadyEnded" :label="$t('cv.projectAlreadyEnded')" hide-details />
					<v-row>
						<v-col cols="12" sm="6" md="4">
							<v-menu v-model="menu.start" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
								<template #activator="{ on, attrs }">
									<v-text-field
										v-model="textDate.start"
										:label="$t('offers.start')"
										:rules="rules.timestamp.start"
										prepend-icon="mdi-calendar"
										readonly
										v-bind="attrs"
										v-on="on"
									/>
								</template>
								<v-date-picker
									v-model="timestamp.start"
									no-title
									scrollable
									@input="
										textDate.start = timestamp.start
										menu.start = false
									"
								/>
							</v-menu>
						</v-col>
						<v-col cols="12" sm="6" md="4" v-if="projectAlreadyEnded">
							<v-menu v-model="menu.end" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
								<template #activator="{ on, attrs }">
									<v-text-field
										v-model="textDate.end"
										:label="$t('cv.end')"
										:rules="rules.timestamp.end"
										prepend-icon="mdi-calendar"
										readonly
										v-bind="attrs"
										v-on="on"
									/>
								</template>
								<v-date-picker
									v-model="timestamp.end"
									scrollable
									@input="
										textDate.end = timestamp.end
										menu.end = false
									"
								/>
							</v-menu>
						</v-col>
					</v-row>
					<v-text-field
						v-model="website"
						:label="$t('companies.website')"
						:counter="websiteMaxLength"
						:rules="rules.website"
						prepend-icon="mdi-earth"
					/>
					<v-textarea
						v-model="description"
						:label="$t('courses.description')"
						:counter="descriptionMaxLength"
						:rules="rules.description"
						outlined
						auto-grow
					/>
				</v-form>
			</v-card-text>
			<v-card-actions>
				<v-spacer />
				<v-btn color="secondary" text @click="switchEditMode()">{{ $t('search.close') }}</v-btn>
				<v-btn color="blue darken-1" text @click="callAddProjectNew()">{{ $t('settings.add') }}</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'CVProjectsAdd',
	props: {
		loadingParent: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			loading: false,
			editMode: false,
			valid: false,
			projectAlreadyEnded: false,
			menu: {
				start: false,
				end: false
			},
			name: '',
			textDate: {
				start: null,
				end: null
			},
			timestamp: {
				start: null,
				end: null
			},
			website: '',
			description: '',
			rules: {
				name: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && v.length <= this.nameMaxLength) || this.$t('rules.length', { length: this.nameMaxLength })
				],
				timestamp: {
					start: [(v) => !!v || this.$t('rules.required')],
					end: [
						(v) => {
							if (this.projectAlreadyEnded && v && this.timestamp.start) return v > this.timestamp.start || this.$t('rules.timestampEnd')
							else return true
						}
					]
				},
				website: [
					(v) => {
						if (v) return (0 <= v.length && (v.startsWith('http://') || v.startsWith('https://'))) || this.$t('rules.website')
						else return true
					},
					(v) => 0 < v.length <= this.websiteMaxLength || this.$t('rules.length', { length: this.websiteMaxLength })
				],
				description: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && v.length <= this.descriptionMaxLength) || this.$t('rules.length', { length: this.descriptionMaxLength })
				]
			}
		}
	},
	computed: {
		nameMaxLength() {
			return 255
		},
		websiteMaxLength() {
			return 128
		},
		descriptionMaxLength() {
			return 1024
		},
		...mapGetters({
			user: 'users/user'
		})
	},
	methods: {
		switchEditMode() {
			this.editMode = !this.editMode
		},
		callAddProjectNew() {
			this.$refs.form.validate()
			if (!this.valid) return
			this.loading = true
			this.addProjectNew({
				userID: this.user.id,
				project: {
					name: this.name,
					ended: this.projectAlreadyEnded,
					timestamp: this.timestamp,
					website: this.website,
					description: this.description
				}
			})
				.then(({ success }) => {
					if (success) this.switchEditMode()
				})
				.then(() => {
					this.loading = false
				})
		},
		...mapActions('cv', ['addProjectNew'])
	}
}
</script>
